import React, { useEffect, useRef, useState } from 'react';
import './HeroSection.css';

function HeroSection() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section className={`hero ${isVisible ? 'visible' : ''}`} ref={sectionRef}>
      <h1 className={`hero-title ${isVisible ? 'animate' : ''}`}>
        Unlock Your Business's Full Potential with Evolvista Solutions
      </h1>
      <p className={`hero-desc ${isVisible ? 'animate' : ''}`}>
        We turn innovative ideas into impactful digital experiences that drive growth and success in a rapidly evolving world.
      </p>
      {/* <button className="btn">Learn More</button> */}
    </section>
  );
}

export default HeroSection;
