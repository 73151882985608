import React, { useEffect, useRef } from 'react';
import values from './values.jpeg';
import './About.css';

function About() {
  const aboutContentRef = useRef(null);
  const aboutImageRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.1, // Lower threshold for mobile devices
    };

    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Add animation classes when the section comes into view
          entry.target.classList.add('fade-in');
          if (entry.target === aboutContentRef.current) {
            aboutImageRef.current.classList.add('slide-in');
          }
        } else {
          // Optionally, remove animation classes when it's out of view
          entry.target.classList.remove('fade-in');
          if (entry.target === aboutContentRef.current) {
            aboutImageRef.current.classList.remove('slide-in');
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    // Start observing both elements
    observer.observe(aboutContentRef.current);
    observer.observe(aboutImageRef.current);

    return () => {
      observer.disconnect(); // Clean up the observer when the component unmounts
    };
  }, []);

  return (
    <section id="about" className="about-container">
      <div ref={aboutContentRef} className="about-content">
        <h2>About Us</h2>
        <p>
          At Evolvista Solutions, we are committed to driving your business forward with a wide range of innovative digital services. Our team of experts specializes in creating engaging mobile apps for both Android and iOS, developing dynamic, responsive websites, and enhancing your online presence through targeted SEO strategies. We also leverage digital marketing to amplify your brand across multiple channels, ensuring maximum visibility. From game development that delivers immersive experiences to quality assurance that guarantees top-notch products, we ensure every solution is designed to meet your business needs. Our UI/UX design services focus on user-centered interfaces, while our AI Solutions leverage cutting-edge technologies to optimize your operations, enhance decision-making, and deliver data-driven insights for your business. With technical support that ensures reliable solutions for ongoing success, Evolvista Solutions is dedicated to delivering exceptional value through technology-driven innovation, helping you succeed in the ever-evolving digital world.
        </p>
      </div>
      <div ref={aboutImageRef} className="about-image">
        <img src={values} alt="About Us" />
      </div>
    </section>
  );
}

export default About;
