import React, { useEffect, useRef, useState } from 'react';
import './Portfolio.css';

function Portfolio() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.2 }
    );
    
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section id="portfolio" ref={sectionRef}>
      <div className={`line ${isVisible ? 'visible' : ''}`}></div>
      <div className={`why-choose-us ${isVisible ? 'visible' : ''}`}>
        <div className={`why-choose-box ${isVisible ? 'visible' : ''}`}>
          <h2>Why Choose Us?</h2>
          <p>
            At Evolvista Solutions, we are dedicated to empowering businesses with tailored digital services that deliver real impact. From dynamic mobile and web applications to immersive game development, advanced UI/UX design, and cutting-edge AI Solutions, our expertise spans every aspect of modern technology. We leverage artificial intelligence to optimize operations, enhance decision-making, and unlock valuable insights for your business. We prioritize innovation, quality, and customer satisfaction, ensuring that every solution drives measurable growth and success.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
