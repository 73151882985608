import React, { useState } from 'react';
import './Header.css';
import logo5 from './logo5.png';

const Header = () => {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive((prevState) => !prevState);  // Use functional update for better state management
  };

  return (
    <header>
      <div className="logo">
        <img src={logo5} alt="Logo" className="logo-img" />
      </div>
      <div className="hamburger" onClick={toggleMenu} aria-label="Toggle menu" role="button">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <nav className={`nav-links ${menuActive ? 'active' : ''}`}>
        <a href="#about" onClick={() => setMenuActive(false)}>Who We Are</a>
        <a href="#services" onClick={() => setMenuActive(false)}>What We Offer</a>
        <a href="#vision" onClick={() => setMenuActive(false)}>The Future We See</a>
        <a href="#contact" onClick={() => setMenuActive(false)}>Connect with Us</a>
      </nav>
    </header>
  );
};

export default Header;
