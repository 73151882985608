import React from 'react';
import './Footer.css'

function Footer() {
  return (
    <footer>
      <p>&copy; 2024 Evolvista Solutions. All rights reserved.</p>
      <p><a href="#">Privacy Policy</a> | <a href="#">Terms of Service</a></p>
    </footer>
  );
}

export default Footer;
