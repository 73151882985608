import React, { useEffect } from 'react';
import './Services.css';
import 'intersection-observer';


function Services() {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Add the fade-in class when the element is in view
            entry.target.classList.add('fade-in');
          } else {
            // Remove the fade-in class when the element leaves the view
            entry.target.classList.remove('fade-in');
          }
        });
      },
      { threshold: 0.3 } // Trigger animation when 30% of the element is in view
    );

    // Observe the service cards
    const serviceCards = document.querySelectorAll('.service-card');
    serviceCards.forEach((card) => observer.observe(card));

    return () => {
      // Clean up the observer when the component unmounts
      observer.disconnect();
    };
  }, []);

  return (
    <section id="services">
      <h2>Our Services</h2>
      <div className="services">
        <div className="service-card">
          <h3>Web Development</h3>
          <p>Our web development team excels in creating dynamic, responsive websites tailored to your needs. Whether you need a simple website or a complex web application, we deliver solutions that provide both functionality and aesthetic appeal.</p>
        </div>
        <div className="service-card">
          <h3>Mobile App Development</h3>
          <p>We specialize in creating engaging mobile applications for both Android and iOS platforms. Our apps are designed to deliver seamless experiences that captivate users and drive business growth.</p>
        </div>
        <div className="service-card">
          <h3>SEO and Digital Marketing</h3>
          <p>We enhance your online presence with a powerful combination of SEO and digital marketing strategies. Our SEO services focus on improving your search engine rankings and driving organic traffic, while our digital marketing strategies amplify your brand’s reach through targeted campaigns across social media, content creation, and paid advertising.</p>
        </div>
        <div className="service-card">
          <h3>Game Development</h3>
          <p>We offer immersive game development services, crafting captivating gaming experiences that engage users and build long-term loyalty. Our solutions cover everything from concept to deployment.</p>
        </div>
        <div className="service-card">
          <h3>UI/UX Design</h3>
          <p>Our design experts create intuitive, user-friendly interfaces that enhance the user experience. By focusing on both form and function, we craft designs that engage users and drive results.</p>
        </div>
        <div className="service-card">
          <h3>AI Solutions</h3>
          <p>Unlock the potential of artificial intelligence with our cutting-edge AI solutions. From intelligent automation to data-driven insights, we empower your business to innovate, optimize operations, and deliver exceptional user experiences.</p>
        </div>
      </div>
    </section>
  );
}

export default Services;
