import React, { useEffect } from 'react';
import './Vision.css';

function Vision() {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Add the fade-in class when the element is in view
            entry.target.classList.add('fade-in');
          } else {
            // Remove the fade-in class when the element leaves the view
            entry.target.classList.remove('fade-in');
          }
        });
      },
      { threshold: 0.3 } // Trigger animation when 30% of the element is in view
    );

    // Observe the section and the vision cards
    const visionSection = document.querySelector('#vision');
    const visionCards = document.querySelectorAll('.vision-card');
    const visionTitle = document.querySelector('#vision h2');
    const visionCardsContainer = document.querySelector('.vision-cards');

    observer.observe(visionSection);
    observer.observe(visionTitle);
    observer.observe(visionCardsContainer);
    visionCards.forEach((card) => observer.observe(card));

    return () => {
      // Clean up the observer when the component unmounts
      observer.disconnect();
    };
  }, []);

  return (
    <section id="vision">
      <h2>Our Vision</h2>
      <div className="vision-cards">
        <div className="vision-card">
          <h3>Innovative Digital Solutions for Business Growth</h3>
          <p>
            At Evolvista Solutions, we focus on transforming businesses through innovative digital services. Our team of experts delivers tailored solutions that help brands excel in the ever-changing digital landscape.
          </p>
        </div>
        <div className="vision-card">
          <h3>Customer-Focused Excellence</h3>
          <p>
            We put our clients first, ensuring that every solution we provide aligns with their needs and drives measurable success. By blending creativity, technology, and a deep understanding of your goals, we craft exceptional experiences.
          </p>
        </div>
        <div className="vision-card">
          <h3>Sustainable Growth and Impact</h3>
          <p>
            We are dedicated to helping businesses grow sustainably by delivering responsible solutions that make a difference. Our goal is to create lasting value for our clients while contributing positively to the digital world.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Vision;
